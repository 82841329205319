import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createUser, deleteUserById, getUserById, updateUser, getMe, getUsersOfCustomer, updateAdmin } from "../../../Services/API";

export const usersCreateSlice = createAsyncThunk("usersCreateSlice", async (data) => {
    try {
        const response = await createUser(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});

export const getUserByIdSlice = createAsyncThunk("getUserByIdSlice", async (data) => {
    try {
        const response = await getUserById(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});
export const userDeleteSlice = createAsyncThunk("userDeleteSlice", async (data) => {
    try {
        const response = await deleteUserById(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});
export const usersUpdateSlice = createAsyncThunk("usersUpdateSlice", async (data) => {
    try {
        if (data?.permission === "A" || data?.permission === "S") {
            const response = await updateAdmin(data, data.header);
            return response;
        }
        else {
            const response = await updateUser(data, data.header);
            return response
        }
    } catch (error) {
        throw error;
    }
});

export const getUsersOfCustomerSlice = createAsyncThunk("getUsersOfCustomerSlice", async (data) => {
    try {
        const response = await getUsersOfCustomer(data, data.header);
        return response
    } catch (error) {
        throw error;
    }
});
const initialState = {
    loading: false,
    error: null,
    data: null
}

export const usersDataSlice = createSlice({
    name: "usersDataSlice",
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(usersCreateSlice.pending, (state) => {
            state.loading = true;
        }).addCase(usersCreateSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }).addCase(usersCreateSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(getUserByIdSlice.pending, (state) => {
            state.loading = true;
        }).addCase(getUserByIdSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }).addCase(getUserByIdSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(usersUpdateSlice.pending, (state) => {
            state.loading = true;
        }).addCase(usersUpdateSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }).addCase(usersUpdateSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(userDeleteSlice.pending, (state) => {
            state.loading = true;
        }).addCase(userDeleteSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }).addCase(userDeleteSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(getUsersOfCustomerSlice.pending, (state) => {
            state.loading = true;
        }).addCase(getUsersOfCustomerSlice.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }).addCase(getUsersOfCustomerSlice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

    },

})
export const { setSidebarOffcanvasShow } = usersDataSlice.actions
export default usersDataSlice.reducer;